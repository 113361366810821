import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/utilities/HeroImage/heroImage"
import AddressFormWrapper from "../components/contactUs/addressFormWrapper"

const ContactUs = () => (
  <Layout>
    <SEO title="Contact us" />
    <HeroImage
      title="Contact us"
      hrefForScrollDown="/contact/#address-form-wrapper"
    />
    <AddressFormWrapper />
  </Layout>
)

export default ContactUs
