import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Importing components
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import ContactUsForm from "../utilities/ContactUsForm/contactUsForm"

// impoting assets
import "./contact-us.scss"

const AddressFormWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      serviceLogoImage: file(relativePath: { eq: "service-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div id="address-form-wrapper">
      <div className="container">
        <div className="content-wrapper">
          <div className="address-info">
            <div className="service-image-wrapper">
              <Img fluid={data.serviceLogoImage.childImageSharp.fluid} />
            </div>
            <div className="text">
              <div className="address">
                <h2>Address</h2>
                <p>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                  111/112/113, Mahatma Phule Peth,
                  <br /> Near Dakshinmukhi Maruti,
                  <br /> Pune- 411 042 MH IN
                </p>
              </div>
              <div className="phone">
                <h2>Phone</h2>
                <p>
                  <FontAwesomeIcon icon={faPhoneAlt} size="lg" />
                  +91-7276727959
                </p>
              </div>
              <div className="email">
                <h2>Email</h2>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} size="lg" />
                  contact@aquis.in
                </p>
              </div>
            </div>
          </div>
          <div id="contact-us-form-wrapper">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressFormWrapper
