import React from "react"

// importing assets
import "./contact-us-form.scss"

const ContactUsForm = () => {
  return (
    <div className="contact-us-form">
      <h2>Send us a message!</h2>
      <form
        name="contact-us-aquis-final"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact-us-aquis" />

        <label htmlFor="full-name">Full name</label>
        <input
          type="text"
          id="full-name"
          name="full-name"
          placeholder="Jane Doe"
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="JaneDoe@gmail.com"
        />

        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          rows="10"
          placeholder="Enter your message here"
        ></textarea>

        <button type="submit">Submit</button>
      </form>
    </div>
  )
}

export default ContactUsForm
